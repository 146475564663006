<template>
  <v-card
    outlined
    class="d-flex rounded-lg pa-0"
    min-height="60"
  >
    <v-row no-gutters>
      <v-col
        v-for="n in 3"
        :key="n"
        :cols="$vuetify.breakpoint.width < 1566 ? (n === 2 ? 6 : undefined) : (n === 2 ? 8 : undefined)"
        class="pa-0"
        :class="n === 2 ? 'mx-2' : ''"
      >
        <v-card
          v-if="n === 1"
          flat
          tile
          height="100%"
          color="lightBackground"
          :class="n === 1 ? 'rounded-l-lg' : n === 3 ? 'rounded-r-lg ' : ''"
          class="d-flex align-center justify-center"
        >
          <v-icon
            v-if="isIcon(document.raw.mimetype, document.raw.originalname)"
            class="mr-2"
            :color="iconType.color"
          >
            {{ iconType.icon }}
          </v-icon>
          <img
            v-if="document && (document.raw.mimetype === 'application/vnd.apple.pages' || document && document.raw.mimetype ==='application/vnd.apple.numbers')"
            :src="getSvg(document.raw.mimetype, document.raw.originalname)"
            height="24px"
            class="mr-3"
            width="auto"
          >
          <img
            v-if="document.raw.mimetype === 'application/octet-stream' && !isIcon(document.raw.mimetype, document.raw.originalname)"
            :src="getOctetValue(document.raw.originalname)"
            height="24px"
            width="auto"
            class="mr-3"
          >
        </v-card>
        <v-card
          v-if="n === 2"
          flat
          height="100%"
          :class="n === 1 ? 'rounded-l-lg' : n === 3 ? 'rounded-r-lg ' : ''"
          class="d-flex align-center justify-start"
        >
          <span
            style="overflow: hidden;"
          >
            {{ document.raw.originalname }}
          </span>
        </v-card>
        <v-card
          v-if="n === 3"
          flat
          color="lightBackground"
          height="100%"
          :class="n === 1 ? 'rounded-l-lg' : n === 3 ? 'rounded-r-lg ' : ''"
          class="d-flex justify-center align-center"
          style="flex-direction: column;"
        >
          <div
            v-if="document.status === 'pending'"
          >
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-progress-circular
                  v-if="document.status === 'pending'"
                  indeterminate
                  size="22"
                  :width="3"
                  color="accent"
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <span>{{ $t('documents|processing_document') }}</span>
            </v-tooltip>
          </div>
          <div
            v-if="document.status === 'error'"
          >
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="error"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-alert
                </v-icon>
              </template>
              <span>{{ $t('documents|processing_error') }}</span>
            </v-tooltip>
          </div>
          <div
            v-if="document.status === 'processed'"
          >
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-if="document.status === 'processed'"
                  color="success"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-check-circle
                </v-icon>
              </template>
              <span>{{ $t('documents|processed_successfully') }}</span>
            </v-tooltip>
          </div>
          <div>
            <v-menu
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  small
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>
                    {{ icons.mdiCog }}
                  </v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  @click="$emit('open-doc')"
                >
                  <v-icon
                    color="primary"
                    class="mr-2"
                  >
                    mdi-arrow-top-right
                  </v-icon>
                  <v-list-item-title>{{ $t('actions|open') }}</v-list-item-title>
                </v-list-item>
                <v-list-item @click="$emit('deleteAction')">
                  <v-icon
                    color="primary"
                    class="mr-2"
                  >
                    mdi-link-off
                  </v-icon>
                  <v-list-item-title>{{ $t('actions|unassign') }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import generalMixin from '@/utils/generalMixin'

export default {
  mixins: [generalMixin],
  props: {
    document: {
      type: Object,
      default: null
    }
  }
}
</script>
