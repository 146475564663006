<template>
  <v-dialog
    v-if="templateData"
    v-model="inPreview"
    scrollable
    max-width="600px"
  >
    <v-card>
      <v-card-title>
        <img
          src="@/assets/Lavvira_monogramm_logo.svg"
          height="28px"
          width="auto"
        >
        <div
          class="ml-3"
        >
          {{ $t('questionnaire|questionnaire') }}
        </div>
        <v-spacer />
        <v-btn
          icon
          @click="inPreview = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text
        class="pt-4"
        style="height: 50vh;"
      >
        <v-card
          v-if="templateData.questionnaire.title"
          min-height="100"
          outlined
          class="mx-auto mb-10 rounded-tl-lg rounded-tr-lg rounded-br-lg rounded-bl-lg"
        >
          <v-card-text
            class="pa-2"
            v-html="templateData.questionnaire.title"
          />
        </v-card>
        <QuestionnaireForm
          :questionnaire="templateData.questionnaire"
          @update="updateQuestionnaire"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          rounded
          dense
          small
          :loading="caseRequestLoading"
          color="primary"
          class="mb-2"
          min-width="125px"
          @click="updateQuestionnaire(templateData.questionnaire)"
        >
          {{ $t('actions|send') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import QuestionnaireForm from '@/components/forms/QuestionnaireFieldEdit/QuestionnaireForm.vue'

export default {
  components: {
    QuestionnaireForm
  },
  data () {
    return {
      inPreview: false,
      templateData: null
    }
  },
  computed: {
    ...mapState({
      caseRequestLoading: state => state.cases.loading
    })
  },
  methods: {
    ...mapActions({
      addToast: 'toasts/addToast'
    }),
    updateQuestionnaire (questionairre) {
      this.$emit('updateQuestionnaire', questionairre, 'from-client')
    }
  }
}
</script>

<style scoped>
</style>
