<template>
  <div>
    {{ $t('questionnaire|questionnaire_fields') }}:
    <template v-for="field in questionnaire.fields">
      <component
        :is="field.fieldType"
        v-if="showComponent(field)"
        :key="field.id"
        v-model="field.value"
        class="my-4"
        :label="field.customLabel ? `${field.name}[${field.customLabel}]` : field.name ? translateField(field) : field.fieldType === 'currency-input' ? 'Amount' : ''"
        :hint="field.hint"
      />
    </template>
    {{ $t('documents|uploaded_documents') }}:
    <template v-for="(doc, index) in questionnaire.documents">
      <v-card
        :key="index"
        flat
        outlined
        class="my-4"
      >
        <v-card-subtitle>
          {{ $t('documents|required_document') }}: {{ getRelevantField(doc._id) }}
        </v-card-subtitle>
        <v-card-text>
          <ClientDocumentItem
            :key="doc._id"
            :document="doc"
            @open-doc="openDocument(doc)"
            @deleteAction="unassignDocFromClient(doc)"
          />
        </v-card-text>
      </v-card>
    </template>
    <FileView
      ref="fileView"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import FileView from '@/components/dialogs/FileView.vue'
import generalMixin from '@/utils/generalMixin.js'
import ClientDocumentItem from '../../../pages/Clients/ClientDocumentItem.vue'

export default {
  components: {
    FileView,
    ClientDocumentItem
  },
  mixins: [generalMixin],
  props: {
    questionnaire: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState({
      settings: state => state.settings.settings,
      actualLanguage: state => state.account.actualLanguage
    })
  },
  methods: {
    showComponent (field) {
      if (field && field.fieldType === 'file-input' && field.isUploaded) return false
      else return true
    },
    getRelevantField (docId) {
      const field = this.questionnaire.fields.find(f => f.documentId === docId)
      if (field && field.fieldType === 'file-input') return this.translateField(field)
      else return this.$t('common|unknown')
    },
    translateField (field) {
      if (!this.settings || !this.settings.fields) return this.$t('common|unknown')
      for (const key in this.settings.fields) {
        if (Object.hasOwnProperty.call(this.settings.fields, key)) {
          const element = this.settings.fields[key]
          if (field.fieldKey.toLowerCase() === key.toLowerCase()) {
            return element.label[this.actualLanguage]
          }
        }
      }
    }
  }
}
</script>
